<template>
  <div>
    <h3 class="center">Первый отчёт студии</h3>
    <div>
      <form v-on:submit.prevent="" class="flex">
        <div class="heap-block">
          <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
        </div>
        <div class="heap-block">
          <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
        </div>
        <div class="heap-block">
          <PointSelector
            label="Точка"
            :allPointsFlag="true"
            :id_pointIn="id_point"
            @change="id_point = $event"
          />
        </div>
        <div class="heap-block">
          <div @click.prevent="tableDataRetrieve" class="high pointer">
            <button class="btn">Обновить</button>
          </div>
        </div>
      </form>
    </div>

    <div v-if="loading" class="body">
      <Loader />
    </div>
    <div v-else class="body">
      <div class="reminder smalltext">работает сортировка по столбцам</div>
      <table class="t_table">
        <tr style="user-select: none; -webkit-user-select: none">
          <th style="width: 5%">№</th>
          <th ref="nickname" @click="sort('nickname')" style="width: 30%" class="pointer">
            Мастер
          </th>
          <th ref="count_s" @click="sort('count_s')" class="pointer">Сеансы</th>
          <th ref="hours_s" @click="sort('hours_s')" class="pointer">Часы (сеансы)</th>
          <th ref="count_a" @click="sort('count_a')" class="pointer">Конс.</th>
          <th ref="hours_a" @click="sort('hours_a')" class="pointer">Часы (конс.)</th>
        </tr>
        <template v-for="(row, i) in data">
          <tr :key="i">
            <td style="width: 5%">{{ i + 1 }}</td>
            <td style="width: 30%; text-align: left">
              {{ row.nickname }}
            </td>
            <td>{{ row.count_s }}</td>
            <td>{{ row.hours_s }}</td>
            <td>{{ row.count_a }}</td>
            <td>{{ row.hours_a }}</td>
          </tr>
        </template>
        <tr>
          <td></td>
          <td></td>
          <td>
            <b>{{ total.count_s }}</b>
          </td>
          <td>
            <b>{{ total.hours_s }}</b>
          </td>
          <td>
            <b>{{ total.count_a }}</b>
          </td>
          <td>
            <b>{{ total.hours_a }}</b>
          </td>
        </tr>
      </table>
      <!-- </template> -->

      <!-- <template v-if="this.total < 0">
        <table class="t_table">
          <tr>
            <td colspan="5">нет данных по выбранным параметрам</td>
          </tr>
        </table>
      </template> -->
    </div>

    <br />
    <br />
  </div>
</template>

<script>
import DateSelector from "@/components/DateSelector.vue";
import PointSelector from "@/components/PointSelector.vue";
import Loader from "@/components/Loader.vue";
import { dateFormatJS } from "@/components-js/dateFormat";
import { DATE_MAX_INTERVAL } from "@/config/settings";
import { request } from "@/components-js/requestSrv";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

export default {
  components: { DateSelector, PointSelector, Loader },

  data() {
    return {
      loading: false,
      // @vue-cli removal
      //      spacer: "\u00A0",
      // @vue-cli removal
      date_begin: null,
      date_end: null,
      DATE_MAX_INTERVAL,
      id_point: 0,
      data: [],
      total: {},
      x: 1,
    };
  },
  beforeCreate() {},

  async mounted() {
    // set default values for dates
    // salary specific - it counts for halfmonths - first half and second half
    // so we need intervals 1 - 15 and 16 - last month day
    const d = new Date();
    let nowDay = d.getDate();
    let nowMonth = d.getMonth();
    let nowYear = d.getFullYear();
    if (nowDay > 16) {
      // need to create 1 - 15 interval
      this.date_begin = new Date(d.setDate(1));
      this.date_end = new Date(d.setDate(15));
    } else {
      this.date_begin = new Date(nowYear, nowMonth - 1, 16);
      this.date_end = new Date(nowYear, nowMonth - 1, new Date(nowYear, nowMonth, 0).getDate());
    }
    this.date_begin = dateFormatJS(this.date_begin);
    this.date_end = dateFormatJS(this.date_end);

    let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
    if (tmp) this.date_begin = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "date_end");
    if (tmp) this.date_end = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "id_point");
    if (tmp) this.id_point = tmp;
    else this.id_point = "%";

    this.tableDataRetrieve();
  },

  methods: {
    // async request(url, method, data) {
    //   // imported function
    //   return request(url, method, data);
    // },

    async tableDataRetrieve() {
      this.loading = true;

      // check if they are dates?
      if (!new Date(this.date_begin) || !new Date(this.date_end)) return;

      // check dates for begin lower, then end
      if (new Date(this.date_begin) > new Date(this.date_end)) this.date_end = this.date_begin;

      // check for interval is not more, than date_max_interval days
      if (
        new Date(
          new Date(this.date_begin).setDate(
            new Date(this.date_begin).getDate() + this.DATE_MAX_INTERVAL
          )
        ) < new Date(this.date_end)
      )
        this.date_end = dateFormatJS(
          new Date(
            new Date(this.date_begin).setDate(
              new Date(this.date_begin).getDate() + this.DATE_MAX_INTERVAL
            )
          )
        );

      // DB request
      this.data = await request(
        "/api/report/turns_by_types",
        "POST",
        {
          date_begin: this.date_begin,
          date_end: this.date_end,
          id_point: this.id_point,
        },
        this.$route.path
      );
      this.loading = false;

      this.total.count_s = 0;
      this.total.hours_s = 0;
      this.total.count_a = 0;
      this.total.hours_a = 0;
      for (let i in this.data) {
        this.total.count_s += parseInt(this.data[i].count_s);
        this.total.hours_s += parseInt(this.data[i].hours_s);
        this.total.count_a += parseInt(this.data[i].count_a);
        this.total.hours_a += parseInt(this.data[i].hours_a);
      }
    },

    sort(col) {
      if (!this.data.length) return;
      this.x *= -1;
      if (col) {
        this.data.sort((a, b) => {
          if (isNaN(parseInt(a[col])) || isNaN(parseInt(b[col]))) {
            // text sort
            if (a[col] < b[col]) return -1 * this.x;
            if (a[col] > b[col]) return 1 * this.x;
            return 0;
          } else {
            // digital sort
            if (parseInt(a[col]) < parseInt(b[col])) return -1 * this.x;
            if (parseInt(a[col]) > parseInt(b[col])) return 1 * this.x;
            return 0;
          }
        });

        for (let name in this.$refs) {
          this.$refs[name].style.fontWeight = "normal";
        }
        this.$refs[col].style.fontWeight = "bold";
      }
    },
  },
};
</script>
